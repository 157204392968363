import React, { useState } from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import styled from 'styled-components'
import { Button } from '../elements'
import { Link } from "gatsby";
import CommunitySVG from '../images/community.svg'
import { colors } from '../styles/colors'
import fullVideo from '../videos/about.mp4'
import signature from '../signature.png'
import cuteboo from '../cuteboo.gif'

const CommunityIMG = styled.img`
position: relative;
width: 40%;
display: block;
margin-right: auto;
margin-left: auto;
`

const PBox = styled(AnimatedBox)`
max-width: 1400px;
margin: 0 auto;
padding-bottom: 3rem;
`

const PButton = styled(Button) <{ color: string }>`
margin-top: 25px;
transition: all 0.5s ease;
background: #2592AE;
color: white;
:hover {
  transition: all 0.4s ease;
  transform: translate3D(0, -1px, 0) scale(1.05);
  background: white;
  color: black;
}
`
const TButton = styled(Button) <{ color: string }>`
margin-top: 25px;
transition: all 0.5s ease;
background: black;
color: white;
:hover {
  transition: all 0.4s ease;
  transform: translate3D(0, -1px, 0) scale(1.05);
  background: white;
  color: black;
}
`

const TextCont2 = styled.div`
position: absolute;
top: 45%;
left: 50%;
margin-right: -35%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
`

const Title = styled.h1`
  color: white;
  font-size: 5vh;
`

const Subtitle = styled.h2`
  color: white;
  font-size: 2.5vh;
`


const Role = styled.div`
  max-width: 1400px;
  letter-spacing: 0.05em;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px 25px;
  margin-top: 25px;
  flex-direction: column;
  margin-bottom: 10px;
  
  @media (min-width: 700px) {
        align-items: center;
        flex-direction: row;
}
`

const RoleItem = styled.div`
  // border: 2px solid black;
  // border-color: white;
  // padding: 14px 28px;
  // font-size: 16px;
  // border-radius: 4px;
  &:not(:last-child):after {
    content: "";
    border: 1px solid #d3d3d3;
    align-self: stretch;
  }
  display: flex;
  flex-direction: column;
  @media (min-width: 700px) {
        align-items: center;
        flex-direction: row;
  }
  gap: 15px 25px;
`

const PostCardStyles2 = styled(AnimatedBox)`
  position: relative;
  text-align: center;
  overflow: hidden;
  height: 100vh;
  text-align: center;  
`;
const PostCardImage2 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;
  align-items: center;
`;
const Cox3Video = styled.video`
  filter: brightness(20%); 
  -webkit-filter: brightness(20%);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  align-items: flex-start;
  padding-top: 10vh;
  padding-left: 375px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  &.is-open {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 1400px) {
    padding-left: 320px;
  }
  @media (max-width: 1125px) {
    padding-left: 2vh;
    padding-right: 2vh;
  }
`

const ModalBox = styled.div`
  width: calc(100vw - 40px);
  max-width: 900px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
  border-radius: 5px;
  background: #fff;
  opacity: 95%;
  padding: 4rem;
  text-align: center;
  position: relative;
  max-height: 80%;
  overflow-y: auto;
  p {
    margin-bottom: 30px;
  }
  @media (max-width: 700px) {
    padding: 30px;
  }
`

const ModalCloseOverlay = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  padding: 0;
`

const Mark1 = styled.mark`
background: linear-gradient(180deg,rgba(255,255,255,0) 50%, #D9EFFC 50%);
color: black;
:hover {
  color: #2592AE;
}
`

const ImgCont = styled.img`
max-width: 100%;
`


const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })


  const categoryAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: 'translate3d(0, -30px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  })

  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Layout color="white">
      <SEO title="About | Conrad" desc="Hi, Conrad here! 👋 I am looking to team up with innovators equally passionate about tackling global challenges and unleashing breakthroughs through co-creation, collaboration, and community." />
      <AnimatedBox style={pageAnimation}>
        <PostCardStyles2 className="post-card">
          <PostCardImage2 className="post-card-image">
            <Cox3Video autoPlay muted loop playsinline>
              <source src={fullVideo} type="video/mp4" />
            </Cox3Video>
          </PostCardImage2>
          <TextCont2>
            <Title style={titleAnimation}>Hi, <mark>Conrad</mark> here! 👋</Title>
            <Subtitle style={titleAnimation}>I Help People <mark>Level Up.</mark></Subtitle>
            <Role style={categoryAnimation}><RoleItem>Author Of The Better Life Framework</RoleItem><RoleItem>Founder Of The Co-x3 Family Foundation</RoleItem>
            </Role>

            <PButton onClick={() => setModalOpen(true)} color="white" py={4} px={8}>
              Learn More
            </PButton>
          </TextCont2>
        </PostCardStyles2>
      </AnimatedBox>

      <ModalContainer className={modalOpen ? 'is-open' : ''}>
        <ModalCloseOverlay type="button" onClick={() => setModalOpen(false)} />
        <ModalBox>
          <h1>About <Mark1>Conrad</Mark1></h1>
          <p style={categoryAnimation}>Conrad Lin is an innovative thought leader, renowned for his groundbreaking work in personal and professional development with <Mark1>The Better Life Framework</Mark1> and his <Mark1>passion to help people level up</Mark1> and be their best self.
            <br /><br />
            With over a decade of experience in building and managing multi-million dollar enterprises and leading teams of thousands of people towards a shared vision, he has <Mark1>refined and distilled his philosophies into clear actionable insights</Mark1>.
            <br /><br />
            In 2020, Conrad started <Mark1>The Co-x3 Foundation</Mark1>, where he launches comprehensive programs that <Mark1>help millions worldwide live their best life</Mark1> - transforming the way people understand themselves, unlock their potential, and make positive impact.
          </p>
          <div>

            {/* <img
              alt="Conrad Lin"
              style={{ height: '70px', padding: '10px -5px' }}
              src={signature}
            /> */}
            </div>
          <a href="https://levelupwithconrad.com/?utm_source=conradlin.com" target="_blank">
            <TButton color="white" py={4} px={8}>
              Level Up With Conrad 🚀
            </TButton>
          </a>
        </ModalBox>
      </ModalContainer>
    </Layout>
  )
}

export default About
